import React, { useState, useRef } from 'react';
import axios from 'axios';
import './App.css';
import logo from './assets/images/logo.png';
import background from './assets/images/background2.png';

function App() {
  const [subject, setSubject] = useState('');
  const [prompt, setPrompt] = useState('');
  const promptRef = useRef(null);
  const [submitted, setSubmitted] = useState(false); // State to track submission

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true); // Set submitted to true
    try {
      const response = await axios.get(`https://legendaryprompts.com/api/generatePrompt?mainSubject=${subject}`);
      setPrompt(response.data);
    } catch (error) {
      console.error("There was an error fetching the prompt!", error);
    }
  };

  const handleCopy = () => {
    promptRef.current.select();
    document.execCommand('copy');
  };

  const handleMidjourney = () => {
    window.open('https://www.midjourney.com/', '_blank');
  };

  return (
    <div className="App">
      <div className="App-header" style={{ backgroundImage: `url(${background})` }}>
        <img src={logo} alt="Legendary Prompt Generator Logo" className="App-logo" />
        <form className="form-container" onSubmit={handleSubmit}>
          <label className="label">Enter Subject Here :</label>
          <input 
            type="text" 
            value={subject}
            className="subject-input"
            onChange={(e) => setSubject(e.target.value)}
          />
          {!submitted && <button type="submit" className="custom-btn generate-btn">Generate Prompt</button>}
        </form>
        {submitted && (
          <>
            <textarea 
              ref={promptRef}
              value={prompt} 
              readOnly 
              className="prompt-output"
              rows="3"
            ></textarea>
            <div className="button-row">
              <button onClick={handleSubmit} className="custom-btn generate-btn">Generate Prompt</button>
              <button onClick={handleCopy} className="custom-btn copy-btn">Copy to Clipboard</button>
              <button onClick={handleMidjourney} className="custom-btn custom-btn-midjourney">Go to Midjourney</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
